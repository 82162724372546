.criteriaModalList {
    list-style-position: inside;
    padding: 0;
    list-style-type: upper-roman;
}

.criteriaModalList>li::marker {
    font-weight: bold;
}

.criteriaModalList li > ol {
    list-style-position: outside;
    list-style-type: upper-roman;
}