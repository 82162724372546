@import url('https://fonts.cdnfonts.com/css/smilage');

html, body, #root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-dark-blue: #181a49;
  --purple: #4d44a0;
  --lilac: #a8157d;
  --green: #71db6f;
}

.bg-dark-blue {
  background-color: var(--bg-dark-blue);
  color: #FFF;
}

.smilage {
  font-family: 'Smilage', sans-serif;
  letter-spacing: 0.04em;
}


/* Bootstrap variants */

.btn-lilac, .bg-lilac {
  background-color: var(--lilac)!important;
  color: #FFF!important;
}


.btn-purple, .bg-purple {
  background-color: var(--purple)!important;
  color: #FFF!important;
}

.btn-dark-blue {
  background-color: var(--bg-dark-blue)!important;
  color: #FFF!important;
}

.bg-green {
  background-color: var(--green)!important;
  color: #FFF!important;
}

.text-green {
  color: var(--green);
}

.text-justify {
  text-align: justify;
}