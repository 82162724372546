.overlayStyle {
    position: absolute;
    border-radius:50%;
    width: 2%;
    height: 2%;
    background: rgb(240,255,0);
    background: radial-gradient(circle, rgba(240,255,0,1) 20%, rgba(240,255,0,0) 60%);
    -webkit-animation: blink 3s infinite both;
            animation: blink 3s infinite both;
}

@-webkit-keyframes blink {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }
  @keyframes blink {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0;
    }
  }